import React from 'react';
import { ScopeWithThreshold } from 'conviva-design/es/components/ScopeWithThreshold';
import get from 'lodash/get';
import { THEME_LIGHT } from '../../../utils/constants';

const Scope = ({ text, rowData }) => {
  const thresholdCategory = get(rowData, ['threshold', rowData.id], null);
  const contentText = rowData.tagDisplayName;
  return (
    <ScopeWithThreshold
      theme={THEME_LIGHT}
      content={[{ text: contentText, type: 'text' }]}
      tooltipPlacement="top"
      category={thresholdCategory}
    />
  );
};

export default Scope;
